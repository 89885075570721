import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CommonContext } from '../context';
import Layout from '../components/layout';
import SubscriptionForm from '../components/SubscriptionForm';
import BoldTitle from '../components/BoldTitle';

const SUBSCRIBERS_COUNT = 80;

const InitialMessage = () => (
  <div className="leading-loose font-regular md:pr-20">
    <b>Stay informed</b> and <b>ahead</b> with our updates and releases. Join
    our <b className="text-primary">{SUBSCRIBERS_COUNT}k+ subscriber</b>{' '}
    community.
  </div>
);

const SuccessMessage = () => (
  <div className="leading-loose font-regular md:pr-20">
    <b>JazakAllah Khairan!</b> Keep an eye on your inbox for updates. Remember
    to share the app with family & friends to share the{' '}
    <b className="text-primary">reward!</b>
  </div>
);

function NewsLetter({ location }) {
  const [status, setStatus] = useState('failed');

  const handleOnSuccess = () => {
    setStatus('success');
  };
  const handleOnFailure = () => {
    setStatus('failed');
  };

  const renderMessage = () => {
    switch (status) {
      case 'success':
        return <SuccessMessage />;
      default:
        return <InitialMessage />;
    }
  };

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        keywords={[`greentech`, `islam`, `projects`, `outsource`]}
        title="Join Our Community"
        description={`Join our global community! Stay informed and ahead with our updates and releases. Join our ${SUBSCRIBERS_COUNT}k+ subscriber community.`}
        location={location}
      >
        <section>
          <div className="flex justify-center w-full bg-primary-0">
            <div className="w-full max-w-6xl p-4 md:grid md:grid-cols-2 md:pt-16 md:pb-20">
              <div className="p-6 md:order-2">
                <img
                  src="/images/news-letter.svg"
                  alt="Greentech Apps Limited Services"
                />
              </div>
              <div className="p-2 md:order-1 md:p-6 md:pt-20">
                <div>
                  <BoldTitle className="mb-6">
                    Join Our Global{' '}
                    <span className="text-primary">Community</span>!
                  </BoldTitle>
                  {renderMessage()}
                  <SubscriptionForm
                    onSuccess={handleOnSuccess}
                    onFailed={handleOnFailure}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </CommonContext.Provider>
  );
}

NewsLetter.propTypes = {
  location: PropTypes.object,
};

export default NewsLetter;
